<template>
  <div>
    <top-menu displayText="회원 가입 시, 2회 무료 화상 수업을 제공합니다!"></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>회원가입</h2>
          </div>
          <section class="study_myinfo_list_area" style="margin-top:-16px;" >
            <div style="width:90%;margin:auto;">
              <section class="study_main_box">
                <div style="width:100%;">
                  <div class="signup_left_box"></div>
                  <div class="signup_right_box">
                    <div id="login_content">
                      <!-- 로그인 폼 시작 -->
                      <form class="login_form" alt="로그인">
                        <div class="login_form_box">
                          <input type="text" class="login_input_box_01_round moon_name" v-model="registerForm.name" placeholder="Name" style="margin-bottom:10px">
                          <input type="email" class="login_input_box_01_round moon_email" v-model="registerForm.email" placeholder="e-mail 주소" alt="이메일 주소" style="margin-bottom:10px">
                          <input type="password" class="login_input_box_01_round moon_pass" v-model="registerForm.password" placeholder="비밀번호" alt="비밀번호 입력">
                          <input type="password" class="login_input_box_01_round moon_pass" v-model="registerForm.password_confirmation" placeholder="비밀번호" alt="비밀번호 확인">
                          <input type="text" class="login_input_box_01_round moon_phone" v-model="registerForm.mobile" placeholder="전화번호" alt="전화번호">
                        </div>
                      </form>
                      <button type="button" class="btn loginBtn_sheet" @click.prevent="register">
                        <a href="#">가입하기</a>
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'home' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Register',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      registerForm: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        mobile: '',
        student_code: ''
      }
    }
  },
  methods: {
    async register () {
      try {
        const response = await useJwt.register(this.registerForm)
        this.$swal({
          title: 'Success!',
          text: response.data,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        this.$router.replace({ name: 'login' })
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data.message,
          icon: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
